































import { Vue, Provide, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/mine'
import { Toast } from 'vant';
@Component({
  components: {
    Nav,
  },
})
export default class Opinion extends Vue {
  @Provide() name = "";
  @Provide() phone = "";
  @Provide() opinion = "";
  @Provide() message = "";
  async submit(){
    const data = {
      content:this.message,
      name:this.name,
      phone:this.phone
    }
    const res = await serve.feedBack(data)
    if(res.data.code === 0){
      Toast.success('提交成功')
      this.message = ""
      this.name = ""
      this.phone = ""
    }else {
      Toast.fail(res.data.message)
    }
  }
}
